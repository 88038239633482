









































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import QuestionItemEditable from '@/partials/components/QuestionItemEditable.vue'
import QuestionsSearch from '@/partials/components/QuestionsSearch.vue'
import ExamPreview from '@/partials/components/Exam/Preview.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ConvertToModelModal from '@/partials/modals/Exams/Drafts/ConvertToModel.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  directives: { mask },
  components: {
    ArrowUp16,
    ArrowDown16,
    TrashCan16,
    QuestionItemEditable,
    ValidationObserver,
    ValidationProvider,
    QuestionsSearch,
    ExamPreview,
    NotifyOnFailed,
    ConvertToModelModal,
    PasswordConfirmField
  }
})
export default class ExamsDraftsEdit extends Mixins(PermissionsMixin, FlashMessageMixin) {
  highlightLevel: Array<string> = []
  isSearchingQuestion = false
  isPreviewingExam = false
  done = true
  convertToModelModalIsOpenened = false

  form: Record<string, any> = {
    code: '',
    type: '',
    draft: true,
    navLock: false,
    switchTabs: false,
    seeScore: false,
    randomOrder: false,
    useQuestionTimer: true,
    useExamTimer: true,
    resetable: true,
    timeMin: 30,
    timeMax: 180,
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  get questionsStatuses () {
    const statuses = this.selectedQuestions.reduce((acc, question) => {
      if (question.level < 26) acc.easy++
      else if (question.level < 75) acc.medium++
      else { acc.hard++ }

      acc.time += question.time
      return acc
    }, { easy: 0, medium: 0, hard: 0, time: 0 })

    statuses.time = Math.floor(statuses.time / 60)

    return statuses
  }

  questionLevelIsInFilter (level: number) {
    const levelStringToInterval: Record<string, any> = {
      easy: {
        start: 0,
        end: 25
      },
      medium: {
        start: 26,
        end: 74
      },
      hard: {
        start: 75,
        end: 100
      }
    }

    if (this.highlightLevel.length === 0 || this.highlightLevel.length === 3) {
      return true
    } else {
      const isInIntervals = this.highlightLevel.some(hLevel => {
        const levelInterval = levelStringToInterval[hLevel]

        if (level >= levelInterval.start && level <= levelInterval.end) {
          return true
        } else {
          return false
        }
      })

      return isInIntervals
    }
  }

  selectedQuestions: Array<Question> = []

  addQuestionsToForm (questions: Array<Question>) {
    this.selectedQuestions.splice(this.positionToInsert, +this.replacing, ...questions)
  }

  positionToInsert = 0
  replacing = false

  searchForQuestions (position = 0, replacing = false) {
    this.isSearchingQuestion = true
    this.positionToInsert = position
    this.replacing = replacing
  }

  created () {
    this.validations()

    axios.get(`exam/${this.$route.params.id}`)
      .then(response => {
        const responseData = camelCaseKeys(response.data.data, { deep: true })

        this.form = {
          code: responseData.code,
          type: responseData.type,
          draft: responseData.draft,
          navLock: responseData.navLock,
          switchTabs: responseData.switchTabs,
          seeScore: responseData.seeScore,
          randomOrder: responseData.randomOrder,
          useQuestionTimer: responseData.useQuestionTimer,
          useExamTimer: responseData.useExamTimer,
          resetable: responseData.resetable,
          timeMin: responseData.timeMin,
          timeMax: responseData.timeMax,
          confirm: '',
          errors: {
            confirm: ''
          }
        }

        this.selectedQuestions = responseData.examQuestions.map((examQuestion: ExamQuestion) => {
          return {
            ...examQuestion.question,
            points: examQuestion.points,
            time: examQuestion.time
          }
        })
      })
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })
  }

  @Watch('isSearchingQuestion')
  scrollToTop () {
    window.scrollTo(0, 0)
  }

  submit () {
    const questions = this.selectedQuestions.map((question, index) => {
      return {
        questionId: question.id,
        order: index,
        points: question.points,
        time: question.time
      }
    })
    const submitForm = {
      ...this.form,
      questions
    }

    this.done = false
    axios.put(`exam/${this.$route.params.id}`, snakeCaseKeys(submitForm, { deep: true }))
      .then(() => {
        this.$router.push({ name: 'ExamsDrafts' }, () => {
          this.setFlashMessage({ message: 'Rascunho editado com sucesso.' })
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.selectedQuestions[targetIndex]

    this.selectedQuestions.splice(targetIndex, 1)
    this.selectedQuestions.splice(index, 0, swappingChoice)
  }

  removeChoice (index: number) {
    this.selectedQuestions.splice(index, 1)
  }

  @Watch('form.useQuestionTimer')
  disableNavLock (useTimerVal: boolean) {
    if (useTimerVal) {
      this.form.navLock = true
    }
  }

  @Watch('form.navLock')
  disableUseQuestionTimer (navLockVal: boolean) {
    if (!navLockVal) {
      this.form.useQuestionTimer = false
    }
  }

  @Watch('form.switchTabs')
  onSwitchTabsChange (switchTabsVal: boolean) {
    if (!switchTabsVal) {
      this.form.resetable = false
    }
  }

  @Watch('form.resetable')
  onResetableChange (resetableVal: boolean) {
    if (resetableVal) {
      this.form.switchTabs = true
    }
  }

  async redirectToModel () {
    await this.toggleModal('convertToModelModalIsOpenened')
    this.$router.push({ name: 'ExamsModelsEdit', params: { id: this.$route.params.id } }, () => {
      this.setFlashMessage({ message: 'Rascunho convertido para Modelo com sucesso.', isSuccess: true })
    })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
